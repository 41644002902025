import ApiService from "../main/apiconnect";

class Connect_login extends ApiService{
    constructor(){
        super('')
    }
    
    login(cred){
        return this.post("/login",cred)
    }

}

export default Connect_login