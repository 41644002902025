import ApiService from "../main/apiconnect";

class Connect_cidades_admin extends ApiService{
    constructor(){
        super('/api')
    }
    
    getCidades(url){
        let params = `/fdt_city?${url}`
        return this.get(params)
    }

    insertCidades(params){
        return this.post("/fdt_city",params)
    }

    updateCidades(params){
        return this.put("/fdt_city",params)
    }

}

export default Connect_cidades_admin