import React from 'react'
import { Switch,BrowserRouter, } from 'react-router-dom'
import Login from '../views/login/login'
import Home from '../views/geral/home'
import PublicRoute from './public-routes'
import PrivateRoute from './private-routes'

const Routes_Profile = () =>{
    return(
        <BrowserRouter>
            <Switch>
                <PublicRoute exact path="/" component={Login}/>
                <PrivateRoute path="/home" component={Home}/>
            </Switch>
        </BrowserRouter>
    )
}

export default Routes_Profile