import Cookie from "js-cookie"
import jwt_decode from "jwt-decode"

export const Auth_login = () =>{
    const auth = Cookie.get("profile.sid")
    if(auth) return true
    else return false
}

export const Auth_Admin_Cliente = ()=>{
    const key ="QKzeT6IMde2yiE80lETtXWyCmqcFK47ZwQAIcqZvDOOB1BvACOkfyzCuY5wsWrOxl0W0OgXAc5U6jMTsV0VpaUTL5haW0x6b2xK2bYxnb1Z6q26I9i47D5hcV14ifGZ2WlEmxgwyTFGZF0mJmhWRSl9EfaYKcCu01SoPO48qGSKemIzXrSiYNl2Mwuw9PQmY3tM7TbdA8vsFO1ZAPA4wDmsCh5i6xVHhObuRsIyn1FNSVyBb6UpOU1vl15OhvX3L"
    const token = Cookie.get("profile.sid")
    if(!token){
        return false
    }
    const decode = jwt_decode(token,key,{ algorithm: 'RS256' }).user
    
    if(Auth_login()&&decode===3){
        return true
    }
    else{
        return false
    }
}

export const Auth_Admin = ()=>{
    const key ="QKzeT6IMde2yiE80lETtXWyCmqcFK47ZwQAIcqZvDOOB1BvACOkfyzCuY5wsWrOxl0W0OgXAc5U6jMTsV0VpaUTL5haW0x6b2xK2bYxnb1Z6q26I9i47D5hcV14ifGZ2WlEmxgwyTFGZF0mJmhWRSl9EfaYKcCu01SoPO48qGSKemIzXrSiYNl2Mwuw9PQmY3tM7TbdA8vsFO1ZAPA4wDmsCh5i6xVHhObuRsIyn1FNSVyBb6UpOU1vl15OhvX3L"
    const token = Cookie.get("profile.sid")
    if(!token){
        return false
    }
    const decode = jwt_decode(token,key,{ algorithm: 'RS256' }).user
    
    if(Auth_login()&&(decode===1||decode===3)){
        return true
    }
    else{
        return false
    }
}

export const Auth_PSA = ()=>{
    const key ="QKzeT6IMde2yiE80lETtXWyCmqcFK47ZwQAIcqZvDOOB1BvACOkfyzCuY5wsWrOxl0W0OgXAc5U6jMTsV0VpaUTL5haW0x6b2xK2bYxnb1Z6q26I9i47D5hcV14ifGZ2WlEmxgwyTFGZF0mJmhWRSl9EfaYKcCu01SoPO48qGSKemIzXrSiYNl2Mwuw9PQmY3tM7TbdA8vsFO1ZAPA4wDmsCh5i6xVHhObuRsIyn1FNSVyBb6UpOU1vl15OhvX3L"
    const token = Cookie.get("profile.sid")
    if(!token){
        return false
    }
    const decode = jwt_decode(token,key,{ algorithm: 'RS256' }).user
    
    if(Auth_login()&&decode===1){
        return true
    }
    else{
        return false
    }
}

export const Auth_User = ()=>{
    const key ="QKzeT6IMde2yiE80lETtXWyCmqcFK47ZwQAIcqZvDOOB1BvACOkfyzCuY5wsWrOxl0W0OgXAc5U6jMTsV0VpaUTL5haW0x6b2xK2bYxnb1Z6q26I9i47D5hcV14ifGZ2WlEmxgwyTFGZF0mJmhWRSl9EfaYKcCu01SoPO48qGSKemIzXrSiYNl2Mwuw9PQmY3tM7TbdA8vsFO1ZAPA4wDmsCh5i6xVHhObuRsIyn1FNSVyBb6UpOU1vl15OhvX3L"
    const token = Cookie.get("profile.sid")
    if(!token){
        return false
    }
    const decode = jwt_decode(token,key,{ algorithm: 'RS256' }).user
    
    if(Auth_login()&&decode===2){
        return true
    }
    else{
        return false
    }
}