import Connect_vendas_admin from "../../../connections/logged-admin/connect-vendas"
import Cookie from "js-cookie"
import order_desc from '../../../components/1.png'
import order_cres from '../../../components/2.png'
import order_null from '../../../components/3.png'
import Geral from "../../../connections/main/geral"

const service = new Connect_vendas_admin()
const service_geral = new Geral()

const change_color_status = (status)=>{
    if(status==='Sucesso WPA'||status==='Sucesso WAM'){
        return {color:"green"}
    }
    else if(status==='Erro Geral'){
        return {color:"red"}
    }
    else if(status==='Erro WAM'){
        return {color:"orange"}
    }
}

const buscar_endpoint = async(vet,setObj,offset,limit,setMaxpage,flag_order,
    orderby,setMaxcount,setMaxerr,setMaxwarn)=>{
    let url = `data_submissaoini=${vet[0]}&status=${vet[1]}&localizador=${vet[3]}\&nome_vendedor=${vet[2]}`
    url=url+`&offset=${offset}&limit=${limit}&flag_order=${flag_order}&orderby=${orderby}`
    url=url+`&nucontrato=${vet[4]}&unidade=${vet[5]}&sala=${vet[6]}&cdnmempreend=${vet[7]}`
    url=url+`&nome_cliente=${vet[8]}&data_submissaofim=${vet[9]}`
    await service.getVendas(url)
    .then(res=>{
        setObj(res.data.data)
        var arrendondar = Math.ceil(res.data.count/20);
        if(arrendondar===0){
            arrendondar=1
        }
        setMaxpage(arrendondar)
        setMaxcount(res.data.count)
        setMaxerr(res.data.erros)
        setMaxwarn(res.data.avisos)
    })
    .catch(erro=>{
        if(erro.response.status===401){
          Cookie.remove('connect.sid')
          Cookie.remove('profile.sid')
          window.location.href="/"
        }
    })
}


const limpar = (setObj,setData_submissaoini,setData_submissaofim,setLocalizador,setNome_vendedor,setStatus,
    setContrato,setUnidade,setSala,setEmpreend,setCliente,
    setCurrentpage,setOffset,setMaxpage,setOrderdt_sub,setOrderstatus,setOrdercliente,setOrderlocali, 
    setOrderempreend, setOrdersala, setOrderunid, setOrdercontrato,setOrdervendedor,
    setFlagdt_sub, setFlagstatus,setFlagcliente,setFlaglocali, setFlagempreend, 
    setFlagsala,setFlagunid,setFlagcontrato,setFlagvendedor,
    setFlag_order,setOrderby,setVisibility,setMaxcount,setMaxerr,setMaxwarn,setTipoprod)=>{
    consult_profile()
    setData_submissaoini(()=>{
        let data = new Date()
        data.setDate(data.getDate() - 4)
        return data.toISOString().split('T')[0]
    })
    setData_submissaofim(()=>{
        let data = new Date()
        data.setDate(data.getDate())
        return data.toISOString().split('T')[0]
    })
    setObj([])
    setLocalizador('')
    setNome_vendedor('')
    setStatus('')
    setContrato('')
    setUnidade('')
    setSala('')
    setEmpreend('')
    setCliente('')
    setCurrentpage(1)
    setOffset(0)
    setMaxpage(1)
    setOrderdt_sub(order_desc)
    setOrderstatus(order_null)
    setOrdercliente(order_null)
    setOrderlocali(order_null)
    setOrderempreend(order_null)
    setOrdersala(order_null)
    setOrderunid(order_null)
    setOrdercontrato(order_null)
    setOrdervendedor(order_null)
    setFlagdt_sub(1)
    setFlagstatus(3)
    setFlagcliente(3)
    setFlaglocali(3)
    setFlagempreend(3)
    setFlagsala(3)
    setFlagunid(3)
    setFlagcontrato(3)
    setFlagvendedor(3)
    setFlag_order(2)
    setOrderby(11)
    setVisibility('hidden')
    setMaxcount(0)
    setMaxerr(0)
    setMaxwarn(0)
    setTipoprod('')
}


const ordernar = async(setfalse1, setfalse2, setfalse3, 
    setfalse4, setfalse5, setfalse6, setfalse7, 
    setfalse8, settrue9,variavel,flag,
    setflagfalse1,setflagfalse2,setflagfalse3,setflagfalse4,
    setflagfalse5,setflagfalse6,setflagfalse7,setflagfalse8,
    setflagtrue9,setOrderdt_sub,setFlagdt_sub,setFlag_order,
    setOrderby) =>{
    consult_profile()
    if(variavel===1){
        setflagtrue9(2)
        settrue9(order_cres)
        setflagfalse8(3)
        setfalse8(order_null)
        setflagfalse7(3)
        setfalse7(order_null)
        setflagfalse6(3)
        setfalse6(order_null)
        setflagfalse5(3)
        setfalse5(order_null)
        setflagfalse4(3)
        setfalse4(order_null)
        setflagfalse3(3)
        setfalse3(order_null)
        setflagfalse2(3)
        setfalse2(order_null)
        setflagfalse1(3)
        setfalse1(order_null)
        setFlag_order(1)
        setOrderby(flag)
    }
    if(variavel===2){
        setflagtrue9(3)
        settrue9(order_null)
        setflagfalse8(3)
        setfalse8(order_null)
        setflagfalse7(3)
        setfalse7(order_null)
        setflagfalse6(3)
        setfalse6(order_null)
        setflagfalse5(3)
        setfalse5(order_null)
        setflagfalse4(3)
        setfalse4(order_null)
        setflagfalse3(3)
        setfalse3(order_null)
        setflagfalse2(3)
        setfalse2(order_null)
        setflagfalse1(3)
        setfalse1(order_null)
        setOrderdt_sub(order_desc)
        setFlagdt_sub(1)
        setFlag_order(2)
        setOrderby(11)
    }
    if(variavel===3){
        setflagtrue9(1)
        settrue9(order_desc)
        setflagfalse8(3)
        setfalse8(order_null)
        setflagfalse7(3)
        setfalse7(order_null)
        setflagfalse6(3)
        setfalse6(order_null)
        setflagfalse5(3)
        setfalse5(order_null)
        setflagfalse4(3)
        setfalse4(order_null)
        setflagfalse3(3)
        setfalse3(order_null)
        setflagfalse2(3)
        setfalse2(order_null)
        setflagfalse1(3)
        setfalse1(order_null)
        setFlag_order(2)
        setOrderby(flag)
    }
}

const fechar_abrir_modal = (setLast_id_logmodal,
    setModaladd,modaladd,objid,statusmodal,setStatusmodal)=>{
    setModaladd(!modaladd)
    setLast_id_logmodal(objid)
    setStatusmodal(statusmodal)
}

const consult_profile=async()=>{
    await service_geral.get_user()
    .catch(erro=>{
        if(erro.response.status===401){
            Cookie.remove('connect.sid')
            Cookie.remove('profile.sid')
            window.location.href="/"
        }
    })
}

export {buscar_endpoint,limpar,ordernar,fechar_abrir_modal,change_color_status}