import ApiService from "./apiconnect";

class Geral extends ApiService{
    constructor(){
        super('')
    }
    
    reconnect(){
        return this.get('/me')
    }

    get_user(){
        return this.get('/get-user')
    }

    destroy(){
        return this.get('/destroy')
    }

    alter_password(cred){
        return this.post('/api/alter-password',cred)
    }

    getToken(){
        return this.get('/profile-usuario')
    }

    sendAlive(){
        return this.get('/alive')
    }

}

export default Geral