import ApiService from "../main/apiconnect";

class Connect_vendas_admin extends ApiService{
    constructor(){
        super('/api')
    }
    
    getVendas(url){
        let params = `/fdt_vendas?${url}`
        return this.get(params)
    }

    getDetalhesVendas(url){
        let params = `/fdt_details?${url}`
        return this.get(params)
    }


}

export default Connect_vendas_admin