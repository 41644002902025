import React from 'react'
import {Redirect,Route} from 'react-router-dom'
import { Auth_login } from './Auth'

const PublicRoute = ({ component:Component,...rest})=>{
    return(
    <Route {...rest} render={ props =>(
        !Auth_login()?(<Component {...props}/>):
        <Redirect to ={{pathname:"/home"}}/>
    )}
    />
    )
}

export default PublicRoute