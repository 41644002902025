import ApiService from "../main/apiconnect";

class Connect_Dashboard_admin extends ApiService{
    constructor(){
        super('/api')
    }
    
    getDashboard(){
        let params = "/fdt_dashboard"
        return this.get(params)
    }

}

export default Connect_Dashboard_admin