import React,{useEffect, useState} from 'react'
import Cookie from "js-cookie"
import Modal from 'react-modal'
import './modal_vendas.css'
import Connect_vendas_admin from '../../../../connections/logged-admin/connect-vendas'
import {change_color_status} from '../functions'
import icon_json from '../../../../components/json_download.png'

const Modal_vendas = (props) =>{

    const service = new Connect_vendas_admin()
    const [objcond,setObjcond] = useState([])
    const [objlog,setObjlog] = useState([])
    const [objvend,setObjvend] = useState([])
    const [fileDownloadUrl,setFileDownloadUrl]= useState(null)

    useEffect(async()=>{
        let url = `id_log_integracao_sienge=${props.id_log}`
        await service.getDetalhesVendas(url)
        .then(res=>{
            console.log(res.data)
            setObjlog(res.data.data_log[0])
            setObjvend(res.data.data_vend)
            setObjcond(res.data.data_cond)
        })
        .catch(erro=>{
            if(erro.response.status===401){
              Cookie.remove('connect.sid')
              Cookie.remove('profile.sid')
              window.location.href="/"
            }
        })   
    },[Modal_vendas])

    const download = ()=>{
        const blob = new Blob([objlog.json_origem_esolution]);                   // Step 3
        const fileDownloadUrl = URL.createObjectURL(blob); // Step 4
        setFileDownloadUrl(fileDownloadUrl,()=>{
            URL.revokeObjectURL(fileDownloadUrl)
            setFileDownloadUrl(null)
        })
    }

    const tbcond_pag = objcond.map(Obj=>{
        return(
            <tr key={Obj.qtde_parcela+Obj.valor_total}>
                <td>{Obj.qtde_parcela}</td>
                <td>{Obj.valor_total}</td>
                <td>{Obj.cod_indexador}</td>
                <td>{Obj.tipo_juros}</td>
                <td>{Obj.perc_periodo}</td>
                <td>{Obj.tipo_cond}</td>
            </tr>
        )
    })

    const tbvendedor = objvend.map(Obj=>{
        return(
            <tr key={Obj.nome_vendedor+Obj.cpf_cnpj}>
                <td><input className="inputfiltro" defaultValue={Obj.nome_vendedor} readOnly/></td>
                <td><input className="inputfiltro" defaultValue={Obj.cpf_cnpj} readOnly/></td>
                <td><input className="inputfiltro" defaultValue={Obj.grupo_empresa} readOnly/></td>
            </tr>
        )
    })

    return(
        <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        className="modal-vendas"
        id="modal-vendas"
        contentLabel="My dialog"
        overlayClassName="myoverlay"
        closeTimeoutMS={50}>
            <div className="container">
                <div className="row" id="rowheader">
                    <label className="labelstatus"
                    style={change_color_status(props.status)}
                    >{props.status}</label>
                    <br/>
                    <label className="labelmsgstatus">{objlog.mensagem}</label>
                </div> 
                <div className="row" id="rowbody">
                    <div className="col" id="coltables">
		<div className="row" id="rowlabel">
                            <div className="col">
                                <b>ID de recebimento</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.id_rec}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Localizador</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.localizador}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Empreendimento WPA</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.cdnempreend}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Empreendimento WAM</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.cdnmempreendaux}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                               <b>Empreendimento eSolution</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.empreend_esolution}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                               <b>Sala</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.sala}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Nome do cliente</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.nome_cliente}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Nome do conjuge</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.nome_conjugue}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Unidade</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.unidade}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Contrato</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.nucontrato}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Data da submissao</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.data_submissao}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Data da entrega da chave</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.data_chave}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Tipo da venda</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.tipo_venda}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Valor total WPA</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.valorwpa}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Valor total WAM</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.valorwam}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Quantidade de parcelas</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.qtdparcela}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Tipo de Acrescimo</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.tipo_acrescimo}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Indexador pro rata</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.indexador_pro_rata}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Taxa de juros</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.taxa_juros}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Tipo de juros</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.tipo_juros}/>
                            </div>
                        </div>
                        <div className="row" id="rowlabel">
                            <div className="col">
                                <b>Multa</b>
                            </div>
                            <div className="col">
                                <input className="inputvalue" readOnly value={objlog.multa}/>
                            </div>
                        </div>
                    </div> 
                    <div className="col">
                        <div className="row">
                            <div className="col" id="colheader">
                                <label className="labelheader">Condicao de pagamento</label>
                            </div> 
                        </div>   
                        <div className="row" id="rowcondpag">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Quantidade <br/>de Parcelas</th>
                                        <th>Valor<br/>Total</th>
                                        <th>Indexador</th>
                                        <th>Tipo de<br/>juros</th>
                                        <th>Percentual <br/>ao periodo</th>
                                        <th>Condicao de <br/>pagamento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tbcond_pag}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col" id="colheader">
                                <label className="labelheader">Vendedores</label>
                            </div> 
                        </div>   
                        <div className="row">
                            <div className="col">
                                <div className="row" id="rowvendedor">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Vendedor</th>
                                                <th style={{width:"30%"}}>CPF/CNPJ</th>
                                                <th style={{width:"12%"}}>Empresa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tbvendedor}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row" style={{justifyContent:"right"}}>
                                    <a
                                        download="json_origem_esolution.json"
                                        href={fileDownloadUrl}
                                        ><img className="imgjson" onClick={e=>download()} src={icon_json} width="40px"/>
                                    </a>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
        </Modal>
    )
}

export default Modal_vendas