import React,{useEffect,useState} from 'react'
import Geral from '../../../connections/main/geral'
import Cookie from "js-cookie"
import './vendas.css'
import Modal_vendas from './components/modal_vendas'

//icones
import clear_off from '../../../components/clear_off.png'
import clear_on from '../../../components/clear_on.png'
import order_desc from '../../../components/1.png'
import order_null from '../../../components/3.png'
import next_page from "../../../components/next.png"
import next_page_off from "../../../components/next_disable.png"
import back_page from "../../../components/back.png"
import back_page_off from "../../../components/back-disable.png"
import first_page from "../../../components/first_page.png"
import first_page_off from "../../../components/first_page_disable.png"
import last_page from '../../../components/final_page.png'
import last_page_off from '../../../components/final_page_disable.png'
import detalhes from '../../../components/detalhes.png'

//fun��es
import {buscar_endpoint,limpar,ordernar,fechar_abrir_modal,change_color_status} from './functions'
import "./vendas.css"

const Vendas = (props)=>{

    useEffect(async()=>{
        const service = new Geral()
        const res = await service.get_user()
        .catch(erro=>{
            if(erro.response.status===401){
              Cookie.remove('connect.sid')
              Cookie.remove('profile.sid')
              window.location.href="/"
            }
          })
    },[Vendas])

    const [data_submissaoini,setData_submissaoini] = useState(()=>{
        let data = new Date()
        data.setDate(data.getDate() - 4)
        return data.toISOString().split('T')[0]
    })
    const [data_submissaofim,setData_submissaofim] = useState(()=>{
        let data = new Date()
        data.setDate(data.getDate())
        return data.toISOString().split('T')[0]
    })
    const [status,setStatus] = useState('')
    const [localizador,setLocalizador]=useState('')
    const [nome_vendedor,setNome_vendedor] = useState('')
    const [contrato,setContrato]=useState('')
    const [unidade,setUnidade]=useState('')
    const [sala,setSala]=useState('')
    const [empreend,setEmpreend]=useState('')
    const [cliente,setCliente]=useState('')
    const [tipoprod,setTipoprod]=useState('')
    const [obj,setObj]=useState([])

    const [changeiconsearch,setChangeiconsearch] = useState(clear_off)
    const [visibility, setVisibility]=useState("hidden")

    const [orderdt_sub,setOrderdt_sub] = useState(order_desc)
    const [orderstatus,setOrderstatus] = useState(order_null)
    const [ordercliente,setOrdercliente] = useState(order_null)
    const [orderlocali,setOrderlocali] = useState(order_null)
    const [orderempreend,setOrderempreend] = useState(order_null)
    const [ordersala,setOrdersala] = useState(order_null)
    const [orderunid,setOrderunid] = useState(order_null)
    const [ordercontrato,setOrdercontrato] = useState(order_null)
    const [ordervendedor,setOrdervendedor] = useState(order_null)
    const [ordertipoprod,setOrdertipoprod] = useState(order_null)

    const [flagdt_sub,setFlagdt_sub] = useState(1)
    const [flagstatus,setFlagstatus] = useState(3)
    const [flagcliente,setFlagcliente] = useState(3)
    const [flaglocali,setFlaglocali] = useState(3)
    const [flagempreend,setFlagempreend] = useState(3)
    const [flagsala,setFlagsala] = useState(3)
    const [flagunid,setFlagunid] = useState(3)
    const [flagcontrato,setFlagcontrato] = useState(3)
    const [flagvendedor,setFlagvendedor] = useState(3)
    const [flagtipoprod,setFlagtipoprod] = useState(3)
    
    const [flag_order,setFlag_order] = useState(2)
    const [orderby,setOrderby] = useState(11)
    const [offset,setOffset] = useState(0)
    const [limit,setLimit] = useState(20)
    const [currentpage,setCurrentpage] = useState(1)
    const [maxpage,setMaxpage] = useState(1)
    const [next,setNext] = useState(next_page_off)
    const [last,setLast] = useState(last_page_off)
    const [first,setFirst] = useState(first_page_off)
    const [back,setBack] = useState(back_page_off)
    const [disablenext,setDisablenext]=useState(true)
    const [disableback,setDisableback]=useState(true)
    const [disablefirst,setDisablefirst]=useState(true)
    const [disablelast,setDisablelast]=useState(true)

    const [modaladd,setModaladd]=useState(false)
    const [statusmodal,setStatusmodal]=useState('')
    const [last_id_logmodal,setLast_id_logmodal]=useState('')

    const [maxcount,setMaxcount] = useState(0)
    const [maxerr,setMaxerr] = useState(0)
    const [maxwarn,setMaxwarn] = useState(0)

    useEffect(()=>{
        setMaxpage(1)
        setCurrentpage(1)
        setLimit(20)
        setOffset(0)
        setObj([])
    },[orderdt_sub,orderstatus,ordercliente,
        orderlocali,orderempreend,ordersala,
        orderunid,ordercontrato,ordervendedor])

    useEffect(async()=>{
        await buscar_endpoint([data_submissaoini,
            status,nome_vendedor,localizador,
            contrato,unidade,sala,empreend,
            cliente,data_submissaofim,tipoprod],setObj,
            offset,limit,setMaxpage,flag_order,orderby,
            setMaxcount,setMaxerr,setMaxwarn)
    },[])


    //Pagina��o
    useEffect(async()=>{
        if(obj!=[]){
            if(currentpage!=1||maxpage!=1){
	    setDisableback(true)
                setDisablefirst(true)
                setBack(back_page_off)
                setFirst(first_page_off)
                setNext(next_page_off)
                setLast(last_page_off)
                setDisablenext(true)
                setDisablelast(true)
                setObj([])
                await buscar_endpoint([data_submissaoini,
                    status,nome_vendedor,localizador,
                    contrato,unidade,sala,empreend,
                    cliente,data_submissaofim,tipoprod],setObj,
                    offset,limit,setMaxpage,flag_order,orderby,
                    setMaxcount,setMaxerr,setMaxwarn)
            }
            if(currentpage-1<1){
                setDisableback(true)
                setDisablefirst(true)
                setBack(back_page_off)
                setFirst(first_page_off)
                
            }
            else{
                setDisableback(false)
                setDisablefirst(false)
                setBack(back_page)
                setFirst(first_page)
            }
            if(currentpage===maxpage){
                setNext(next_page_off)
                setLast(last_page_off)
                setDisablenext(true)
                setDisablelast(true)
            }
            else{
                setNext(next_page)
                setLast(last_page)
                setDisablenext(false)
                setDisablelast(false)
            }
        }
    },[currentpage,maxpage])

    useEffect(async()=>{
        setObj([])
        await buscar_endpoint([data_submissaoini,
            status,nome_vendedor,localizador,
            contrato,unidade,sala,empreend,
            cliente,data_submissaofim,tipoprod],setObj,
            offset,limit,setMaxpage,flag_order,orderby,
            setMaxcount,setMaxerr,setMaxwarn)
    },[flag_order,orderby])

    //Ao alterar campos de vendas, localizador, status ou data de submissao, reseto esses itens
    useEffect(()=>{
        setMaxpage(1)
        setCurrentpage(1)
        setLimit(20)
        setOffset(0)
        setObj([])
    },[data_submissaoini,data_submissaofim,status,localizador,nome_vendedor,
    contrato,unidade,sala,empreend,cliente])

    const rows = obj.map( Obj=>{

        if(Obj.last_id_log_integracao_sienge===''){
            return (
                <tr key={Obj.last_id_log_integracao_sienge+Obj.status}>
                    <td><input className="inputtd" defaultValue={Obj.data_submissao} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.status} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.nome_cliente} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.localizador} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.cdnmempreend} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.sala} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.nucontrato} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.unidade} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.nome_vendedor} readOnly/></td>
                    <td></td>
                </tr>
            )
        }
        else{
            return (
                <tr key={Obj.last_id_log_integracao_sienge+Obj.status}>
                    <td><input className="inputtd" defaultValue={Obj.data_submissao} readOnly/></td>
                    <td><input className="inputtd" style={change_color_status(Obj.status)} defaultValue={Obj.status} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.nome_cliente} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.localizador} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.cdnmempreend} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.sala} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.unidade} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.nucontrato} readOnly/></td>
                    <td><input className="inputtd" defaultValue={Obj.nome_vendedor} readOnly/></td>
                    <td>
                        <button className="btnedit" onClick={e=>fechar_abrir_modal
                            (setLast_id_logmodal,setModaladd,
                                modaladd,Obj.last_id_log_integracao_sienge,
                                Obj.status,setStatusmodal
                            )}>
                        <img className="btnimg" src={detalhes} width="18px"/>
                        </button>
                    </td>
                </tr>
            )
        }
    })
    const component_modal = ()=>{
        if(modaladd===true){
            return(
                <Modal_vendas isOpen={modaladd} onRequestClose={e=>{setModaladd(!modaladd)}}
                id_log={last_id_logmodal} status={statusmodal} />
            )
        }
    }

    const enable_disable_dt = ()=>{
        if(visibility==="visible"){
            setVisibility("hidden")
        }
        else if(visibility==="hidden"){
            setVisibility("visible")
        }
        
    }

    const keyEnter = async(event)=>{
        if(event.charCode ===13){
            await buscar_endpoint([data_submissaoini,
                status,nome_vendedor,localizador,
                contrato,unidade,sala,empreend,
                cliente,data_submissaofim,tipoprod],setObj,
                offset,limit,setMaxpage,flag_order,orderby,
                setMaxcount,setMaxerr,setMaxwarn)
        }
    }


    return(
        <div className="vendas-tabela">
            <div id="container">
                <div className="row" id="rowheader" >
                    <div className="col-md-3" id="colheader" style={{visibility:visibility}}>
                        <div className="row" id="rowlabels" style={{visibility:visibility}}>
                            <div className="col" style={{height:"100%"}}>
                                <div className="row">
                                    <label className="labeldateini">Data de Inicio</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <label className="labeldatefim">Data de Fim</label>
                                </div> 
                            </div>
                        </div>
                        <div className="row" style={{visibility:visibility}}>
                            <div className="col">
                                <div className="row"  id="rowinputheader">
                                    <input type="date" className="inputdata"
                                    value={data_submissaoini}
                                    onChange={e=>{
                                        setData_submissaoini(e.target.value)
                                    }}
                                    onKeyPress={keyEnter}/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row"  id="rowinputheader">
                                    <input type="date" className="inputdata"
                                    value={data_submissaofim}
                                    onChange={e=>{
                                        setData_submissaofim(e.target.value)
                                    }}
                                    onKeyPress={keyEnter}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" id="rowtable">
                    <div className="col">
                        <table>
                            <thead>
                                <tr>
                                    <th className="thdt">
                                        <button className="btnth" style={{marginTop:"6px"}}
                                        onClick={e=>{
                                            ordernar(setOrderstatus, setOrdercliente, setOrderlocali, 
                                            setOrderempreend, setOrdersala, setOrderunid, setOrdercontrato, 
                                            setOrdervendedor,setOrderdt_sub,flagdt_sub,11,
                                            setFlagstatus, setFlagcliente, setFlaglocali, setFlagempreend, 
                                            setFlagsala, setFlagunid, setFlagcontrato, setFlagvendedor,
                                            setFlagdt_sub,setOrderdt_sub,setFlagdt_sub,setFlag_order,
                                            setOrderby
                                            )
                                        }}>
                                            <img src={orderdt_sub} width="17px"/>
                                        </button>
                                        <button className="btndtsub" onClick={e=>enable_disable_dt()}>
                                            <label className="labeldtsubbtn">Data de<br/>Submissao</label>
                                        </button>
                                    </th>
                                    <th className="thstatus">
                                        <button className="btnth"
                                        onClick={e=>{
                                            ordernar(setOrderdt_sub, setOrdercliente, setOrderlocali, 
                                            setOrderempreend, setOrdersala, setOrderunid, setOrdercontrato, 
                                            setOrdervendedor,setOrderstatus,flagstatus,12,
                                            setFlagcliente, setFlaglocali, setFlagempreend, setFlagsala, 
                                            setFlagunid, setFlagcontrato, setFlagvendedor,setFlagdt_sub, 
                                            setFlagstatus,setOrderdt_sub,setFlagdt_sub,setFlag_order,
                                            setOrderby
                                            )
                                        }}>
                                            
                                            <img src={orderstatus} width="17px"/>
                                        </button>
                                        <input placeholder="Status   "
                                            value={status}
                                            type="text"
                                            className="contrato"
                                            onKeyPress={keyEnter}
                                            onChange={e=>{
                                                setStatus(e.target.value)
                                            }}>
                                        </input>
                                    </th>
                                    <th className="thcliente">
                                        <button className="btnth"
                                        onClick={e=>{
                                            ordernar(setOrderdt_sub,setOrderstatus,setOrderlocali, 
                                            setOrderempreend, setOrdersala, setOrderunid, setOrdercontrato, 
                                            setOrdervendedor,setOrdercliente,flagcliente,5,
                                            setFlaglocali, setFlagempreend, setFlagsala, setFlagunid, 
                                            setFlagcontrato, setFlagvendedor,setFlagdt_sub, setFlagstatus,
                                            setFlagcliente,setOrderdt_sub,setFlagdt_sub,setFlag_order,
                                            setOrderby
                                            )
                                        }}>
                                            <img src={ordercliente} width="17px"/>
                                        </button>
                                        <input placeholder="Cliente   "
                                            value={cliente}
                                            type="text"
                                            className="nome_vendedor"
                                            onKeyPress={keyEnter}
                                            onChange={e=>{
                                                setCliente(e.target.value)
                                            }}>
                                        </input>
                                    </th>
                                    <th className="thlocalizador">
                                        <button className="btnth"
                                        onClick={e=>{
                                            ordernar(setOrderdt_sub,setOrderstatus,setOrdercliente, 
                                            setOrderempreend, setOrdersala, setOrderunid, setOrdercontrato, 
                                            setOrdervendedor,setOrderlocali,flaglocali,3,
                                            setFlagempreend, setFlagsala, setFlagunid, setFlagcontrato, 
                                            setFlagvendedor,setFlagdt_sub, setFlagstatus,setFlagcliente,
                                            setFlaglocali,setOrderdt_sub,setFlagdt_sub,setFlag_order,
                                            setOrderby
                                            )
                                        }}>
                                            <img src={orderlocali} width="17px"/>
                                        </button>
                                        <input placeholder="Localizador   "
                                            value={localizador}
                                            type="text"
                                            className="unidade"
                                            onKeyPress={keyEnter}
                                            onChange={e=>{
                                                setLocalizador(e.target.value)
                                            }}>
                                        </input>
                                    </th>
                                    <th className="thempreend">
                                        <button className="btnth"
                                        onClick={e=>{
                                            ordernar(setOrderdt_sub,setOrderstatus,setOrdercliente, 
                                                setOrderlocali, setOrdersala, setOrderunid, setOrdercontrato, 
                                            setOrdervendedor,setOrderempreend,flagempreend,6,
                                            setFlagsala, setFlagunid, setFlagcontrato, setFlagvendedor,
                                            setFlagdt_sub, setFlagstatus,setFlagcliente,setFlaglocali, 
                                            setFlagempreend,setOrderdt_sub,setFlagdt_sub,setFlag_order,
                                            setOrderby
                                            )
                                        }}>
                                            <img src={orderempreend} width="17px"/>
                                        </button>
                                        <input placeholder="Empreendimento   "
                                            value={empreend}
                                            type="text"
                                            className="empreend"
                                            onKeyPress={keyEnter}
                                            onChange={e=>{
                                                setEmpreend(e.target.value)
                                            }}>
                                        </input>
                                    </th>
                                    <th className="thsala">
                                        <button className="btnth"
                                        onClick={e=>{
                                            ordernar(setOrderdt_sub,setOrderstatus,setOrdercliente, 
                                                setOrderlocali, setOrderempreend, setOrderunid, setOrdercontrato, 
                                            setOrdervendedor,setOrdersala,flagsala,7,
                                            setFlagunid, setFlagcontrato, setFlagvendedor,setFlagdt_sub,
                                            setFlagstatus,setFlagcliente,setFlaglocali, setFlagempreend, 
                                            setFlagsala,setOrderdt_sub,setFlagdt_sub,setFlag_order,
                                            setOrderby
                                            )
                                        }}>
                                            <img src={ordersala} width="17px"/>
                                        </button>
                                        <input placeholder="Sala   "
                                            value={sala}
                                            type="text"
                                            className="sala"
                                            onKeyPress={keyEnter}
                                            onChange={e=>{
                                                setSala(e.target.value)
                                            }}>
                                        </input>
                                    </th>
                                    <th className="thunid">
                                        <button className="btnth"
                                        onClick={e=>{
                                            ordernar(setOrderdt_sub,setOrderstatus,setOrdercliente, 
                                                setOrderlocali, setOrderempreend, setOrdersala, setOrdercontrato, 
                                            setOrdervendedor,setOrderunid,flagunid,9,
                                            setFlagcontrato, setFlagvendedor,setFlagdt_sub, setFlagstatus,
                                            setFlagcliente,setFlaglocali, setFlagempreend, setFlagsala,
                                            setFlagunid,setOrderdt_sub,setFlagdt_sub,setFlag_order,
                                            setOrderby
                                            )
                                        }}>
                                            <img src={orderunid} width="17px"/>
                                        </button>
                                        <input placeholder="Unidade   "
                                            value={unidade}
                                            type="text"
                                            className="unidade"
                                            onKeyPress={keyEnter}
                                            onChange={e=>{
                                                setUnidade(e.target.value)
                                            }}>
                                        </input>
                                    </th>
                                    <th className="thcontrato">
                                        <button className="btnth"
                                        onClick={e=>{
                                            ordernar(setOrderdt_sub,setOrderstatus,setOrdercliente, 
                                                setOrderlocali, setOrderempreend, setOrdersala, setOrderunid, 
                                            setOrdervendedor,setOrdercontrato,flagcontrato,8,
                                            setFlagvendedor,setFlagdt_sub, setFlagstatus,setFlagcliente,
                                            setFlaglocali, setFlagempreend, setFlagsala,setFlagunid,
                                            setFlagcontrato,setOrderdt_sub,setFlagdt_sub,setFlag_order,
                                            setOrderby
                                            )
                                        }}>
                                            <img src={ordercontrato} width="17px"/>
                                        </button>
                                        <input placeholder="Contrato   "
                                            value={contrato}
                                            type="text"
                                            className="contrato"
                                            onKeyPress={keyEnter}
                                            onChange={e=>{
                                                setContrato(e.target.value)
                                            }}>
                                        </input>
                                    </th>
                                    <th className="thvendedor">
                                        <button className="btnth"
                                        onClick={e=>{
                                            ordernar(setOrderdt_sub,setOrderstatus,setOrdercliente, 
                                                setOrderlocali, setOrderempreend, setOrdersala, setOrderunid, 
                                                setOrdercontrato,setOrdervendedor,flagvendedor,10,
                                                setFlagdt_sub, setFlagstatus,setFlagcliente,setFlaglocali, 
                                                setFlagempreend, setFlagsala,setFlagunid,setFlagcontrato,
                                                setFlagvendedor,setOrderdt_sub,setFlagdt_sub,setFlag_order,
                                                setOrderby
                                                )
                                        }}>
                                            <img src={ordervendedor} width="17px"/>
                                        </button>
                                        <input placeholder="Vendedor   "
                                            value={nome_vendedor}
                                            type="text"
                                            className="nome_vendedor"
                                            onKeyPress={keyEnter}
                                            onChange={e=>{
                                                setNome_vendedor(e.target.value)
                                            }}>
                                        </input>
                                    </th>
                                    <th className="thdet">
                                        <button className="btnlimpar"
                                            onClick={e=>{limpar(setObj,setData_submissaoini,setData_submissaofim,
                                            setLocalizador,setNome_vendedor,setStatus,
                                            setContrato,setUnidade,setSala,setEmpreend,setCliente,
                                            setCurrentpage,setOffset,setMaxpage,setOrderdt_sub,setOrderstatus,setOrdercliente,setOrderlocali, setOrderempreend, 
                                            setOrdersala, setOrderunid, setOrdercontrato,setOrdervendedor,
                                            setFlagdt_sub, setFlagstatus,setFlagcliente,setFlaglocali, setFlagempreend, 
                                            setFlagsala,setFlagunid,setFlagcontrato,setFlagvendedor,
                                            setFlag_order,setOrderby,setVisibility,setMaxcount,setMaxerr,setMaxwarn,setTipoprod)
                                            
                                            setChangeiconsearch(clear_on)
                                            
                                            }}>
                                            <img src={changeiconsearch} width="20px"
                                            onMouseOut={()=>{
                                                setChangeiconsearch(clear_off)
                                            }}/>
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row" id="rowfooter">
                    <div className="col" style={{marginTop:'1.3%'}}>
                        <div className="row" style={{height:"100%"}}>
                            <div className="col-md-1">
                                
                            </div>
                            <div className="col-md-9" id="qtdade">
                                <div className="row" >
                                    <div className="col" >
                                        <div className="row" style={{borderRight:"2px solid white"}}>
                                            <label >Total</label>
                                        </div>
                                        <div className="row" style={{height:"50%"}} style={{borderRight:"2px solid white"}}>
                                            <label style={{fontSize:"10px"}}>{maxcount}</label>
                                        </div>
                                    </div>
                                    <div  className="col">
                                        <div className="row" style={{borderRight:"2px solid white"}}>
                                            <label style={{color:"red"}}>Erro</label>
                                        </div>
                                        <div className="row" style={{borderRight:"2px solid white"}}>
                                            <label style={{fontSize:"10px",color:"red"}}>{maxerr}</label>
                                        </div>
                                    </div>
                                    <div  className="col">
                                        <div className="row" style={{borderRight:"2px solid white"}}>
                                            <label style={{color:"orange"}}>Aviso</label>
                                        </div>
                                        <div className="row" style={{borderRight:"2px solid white"}}>
                                            <label style={{fontSize:"10px",color:"orange"}}>{maxwarn}</label>
                                        </div>
                                    </div>
                                    <div  className="col" style={{borderRight:"2px solid white"}}>
                                        <div className="row">
                                            <label style={{color:"#bbbbbb"}}>Contingencia</label>
                                        </div>
                                        <div className="row">
                                            <label style={{fontSize:"10px",color:"#bbbbbb"}}>0</label>
                                        </div>
                                    </div>
                                    <div  className="col">
                                        <div className="row">
                                            <label style={{color:"green"}}>Sucesso</label>
                                        </div>
                                        <div className="row">
                                            <label style={{fontSize:"10px",color:"green"}}>{maxcount-maxwarn-maxerr}</label>
                                        </div>
                                    </div>
                                </div> 
                                  
                            </div>
                            <div className="col-md-1">

                            </div>
                        </div>
                    </div>
                    <div className="col" style={{marginTop:'0.6%'}}>
                        <button className="btnpage"
                        disabled={disablefirst}
                        onClick={()=>{
                            setCurrentpage(1)
                            setOffset(0)
                        }}>
                            <img src={first} width="15px" style={{maxHeight:"20px",minHeight:'20px'}}/>
                        </button>

                        <button className="btnpage"
                        disabled={disableback}
                        onClick={()=>{
                            setCurrentpage(currentpage-1)
                            setOffset(offset-20)
                        }}>
                            <img src={back} width="12px"/>
                        </button>

                        {currentpage}/{maxpage}

                        <button className="btnpage"
                        disabled={disablenext}
                        onClick={()=>{
                            setCurrentpage(currentpage+1)
                            setOffset(offset+20)
                        }}>
                            <img src={next} width="12px"/>
                        </button>

                        <button className="btnpage"
                        disabled={disablelast}
                        onClick={()=>{
                            setCurrentpage(maxpage)
                            setOffset(maxpage*20-20)
                        }}>
                            <img src={last} width="15px" style={{maxHeight:"20px",minHeight:'20px'}}/>
                        </button>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col">

                            </div>
                            <div className="col">
                                <div className="row">

                                </div>
                            </div>
                            <div className="col">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {component_modal()}
        </div>   
    )
}

export default Vendas