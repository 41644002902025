import react,{ useEffect,useState,Component } from 'react'
import './dashboard.css'
import Geral from '../../../connections/main/geral'
import Cookie from "js-cookie"
import enter from "../../../components/enter.png"

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer
} from 'recharts';
import Connect_Dashboard_admin from '../../../connections/logged-admin/connect-dashboard'

const Dashboard = (props)=>{
    const service_dashboard = new Connect_Dashboard_admin()
    const [last4,setLast4] = useState([])
    const [data,setData] = useState([])
    const [data2,setData2] = useState([])
    const [total_now,setTotal_now] =useState(0)
    const [total_success_now,setTotal_success_now] =useState(0)
    const [total_error_wpa_now,setTotal_error_wpa_now] =useState(0)
    const [total_error_wam_now,setTotal_error_wam_now] =useState(0)

    useEffect(async()=>{
        await buscar()
    },[])

    useEffect(async()=>{
        const service = new Geral()
        await service.get_user()
        .then(res=>{
            if(res.data.profile===2){
                props.history.push("/home")
            }
        })
        .catch(erro=>{
            if(erro.response.status===401){
              Cookie.remove('connect.sid')
              Cookie.remove('profile.sid')
              window.location.href="/"
            }
          })
    },[Dashboard])

    const govendas = () =>{
        props.history.push("/home/admin-vendas")
    }

    const buscar = async()=>{
        await service_dashboard.getDashboard()
        .then(res=>{
            setLast4(res.data.last_4_logs)
            setData(res.data.data)
            setData2(res.data.data2)
            setTotal_now(res.data.total_now)
            setTotal_success_now(res.data.total_success_now)
            setTotal_error_wpa_now(res.data.total_error_wpa_now)
            setTotal_error_wam_now(res.data.total_error_wam_now)
        })
        .catch(erro=>{
            if(erro.response.status===401){
              Cookie.remove('connect.sid')
              Cookie.remove('profile.sid')
              window.location.href="/"
            }
        })
    }

    const rows = last4.map(Obj=>{
        return(
            <tr>
                <td>
                    <input className="inputtable"
                    defaultValue={Obj.status} readOnly
                    />
                </td>
                <td>
                    <input className="inputtable"
                    defaultValue={Obj.localizador} readOnly
                    />
                </td>
                <td>
                    <input className="inputtable"
                    defaultValue={Obj.nome_cliente} readOnly
                    />
                </td>
            </tr>
        )
    })

    return(
        <div className="admin-dashboard">
            <div id="containertotal">
                <div className="row">
                    <div className="col">
                        <div className="row" style={{textAlign:"left",borderLeft:"5px solid #5c5c5c"}}>
                            <label style={{fontWeight:'bold',fontSize:'28px',marginLeft:"1%"}}>Dashboard</label>
                            <label style={{fontSize:'13px',marginLeft:"1%",marginBottom:"0.2%"}}>Boas Vindas Administrador!</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{height:"3%"}}/>
                <div className="row">
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col">
                                <div className="row" id="rowvendashoje">
                                    <div className="col">
                                        <label className="labelvendashoje">Vendas Hoje</label>
                                        <div className="row">
                                            <div className="col" id="colvendashoje">
                                                <div className="row">
                                                    <div className="col">
                                                        <label>{total_now}</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>Itens Totais</label>
                                                    </div>
                                                </div>                                        
                                            </div>
                                            <div className="col" id="colvendashoje">
                                                <div className="row"  style={{borderLeft:"2px solid #5c5c5c"}}>
                                                    <div className="col">
                                                        <label>{total_success_now}</label>
                                                    </div>
                                                </div>
                                                <div className="row"  style={{borderLeft:"2px solid #5c5c5c"}}>
                                                    <div className="col">
                                                        <label>Total êxitos</label>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row" id="rowerros">
                                    <div className="col">
                                        <label className="labelvendashoje">Erros por Tipo Hoje</label>
                                        <div className="row">
                                            <div className="col" id="colvendashoje">
                                                <div className="row">
                                                    <div className="col">
                                                        <label>{total_error_wpa_now}</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>Erros WPA</label>
                                                    </div>
                                                </div>                                        
                                            </div>
                                            <div className="col" id="colvendashoje">
                                                <div className="row" style={{borderLeft:"2px solid #5c5c5c"}}>
                                                    <div className="col">
                                                        <label>{total_error_wam_now}</label>
                                                    </div>
                                                </div>
                                                <div className="row" style={{borderLeft:"2px solid #5c5c5c"}}>
                                                    <div className="col">
                                                        <label>Erros WAM</label>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" id="rowgraficos">
                            <div className="col-md-5" id="colgraficosstatus" style={{alignItems:"center"}}>
                                <div className="row" style={{borderRight:"2px solid #5c5c5c"}}>
                                    <label style={{fontWeight:'bold',fontSize:'18px'}}>Últimos 30 dias</label>
                                </div>
                                <div className="row" style={{borderRight:"2px solid #5c5c5c"}}>
                                    <label style={{fontSize:"13px"}}>Vendas e seus status</label>
                                </div>
                            </div>
                            <div className="col">
                                <ResponsiveContainer width="70%" height={200}>
                                    <PieChart className="piechart">
                                        <Legend layout='vertical' align='left' wrapperStyle={{
                                            marginBottom:'14%',fontSize:'15px'
                                        }}/>
                                        <Pie data={data2} dataKey="value" outerRadius={65} legendType='square'
                                        margin={{top: 0, right: 0, left: 250, bottom: 0}}>
                                            {
                                            data2.map((entry, index) => {
                                                return(
                                                <Cell key={`cell-${index}`} fill={entry.color}/>
                                            )})
                                            }
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col" id="colvendasrecent">
                                <div className="row">
                                    <label style={{fontWeight:"bold",fontSize:"20px"}}>Vendas Recentes</label>
                                </div>
                                <div className="row">
                                    <label style={{fontSize:"13px"}}>Os 4 logs mais recentes</label>
                                </div>
                                <div className="row">
                                    <div className="col" style={{width:"85%"}}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{width:'130px'}}>Status</th>
                                                    <th style={{width:'130px'}}>Localizador</th>
                                                    <th>Cliente</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:'4%'}}>
                                    <div className="col">
                                        <button className="btngotablevendas" onClick={govendas}>
                                            <label className="lbgotablevendas">Ver todas as Vendas</label>
                                        </button>
                                        <img src={enter} width="20px" style={
                                                {marginLeft:'3px',marginBottom:'6px'
                                            }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" id="rowgrafics">
                    <div className="col" >
                        <div className="row" style={{fontWeight:"bold",fontSize:"20px"}}>
                            <label>Último Ano</label>
                        </div>
                        <ResponsiveContainer width="100%" height={200}>
                            <LineChart data={data}
                            >
                                <XAxis interval={0} angle={20} dx={20}  dataKey="name" fontSize="10px"/>
                                <YAxis/>
                                <CartesianGrid stroke="none"/>
                                <Tooltip position={{y:0}} wrapperStyle={{
                                    marginRight:'100%'
                                }}/>
                                <Line type="linear" dataKey="Sucessos" stroke="green" />
                                <Line type="linear" dataKey="Erro_Geral" stroke="red" />
                                <Line type="linear" dataKey="Erro_WAM" stroke="orange" />
                                <Line type="linear" dataKey="Contingencia" stroke="#bbbbbb" />
                                <Legend iconType='square' layout='vertical' align='left' wrapperStyle={{
                                    marginBottom:'3%', fontSize:'15px'
                                }}/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard