import React,{useEffect,useState}  from 'react'
import 'bootswatch/dist/litera/bootstrap.css'
import 'toastr/build/toastr.css'
import 'toastr/build/toastr.min.js'
import Cookie from "js-cookie"
import Routes_Profile from './routes-profile'
import 'primereact/resources/themes/saga-orange/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import Geral from '../connections/main/geral'
import Modal from 'react-modal'
import Crontab from "reactjs-crontab";
import './App.css'

Modal.setAppElement('#root')
const App=()=> {

  const service = new Geral()

  useEffect(async()=>{
    const res = await service.reconnect()
    .catch(erro=>{
      if(erro.status===401&&window.location.href!="/"){
        Cookie.remove('connect.sid')
        window.location.href="/"
      }
    })
  },[App])

  const insere_log_backend = async () => {
    try{
      await service.sendAlive()
    }
    catch(erro){
        console.log(erro)
    }
  };

  const tasks = React.useMemo(
    async () => [
      {
        fn: await insere_log_backend(),
        config: "*/5 * * * *"
      }
    ],
    []
  )

  return (
   <div style={{width:"100%"}}>
     <Crontab
      tasks={tasks}
      timeZone="UTC"
      // timezone is UTC timezone.
      dashboard={{
        hidden: true
        // if true, dashboard is hidden
      }}
    />
    <Routes_Profile/>
   </div>
  );
}

export default App;
