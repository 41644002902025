import react,{useEffect,useState} from 'react'
import Geral from '../../../connections/main/geral'
import Cookie from "js-cookie"
import './cidade.css'

//icones
import adicionar from "../../../components/adicionar-icon.png"
import next_page from "../../../components/next.png"
import next_page_off from "../../../components/next_disable.png"
import back_page from "../../../components/back.png"
import back_page_off from "../../../components/back-disable.png"
import first_page from "../../../components/first_page.png"
import first_page_off from "../../../components/first_page_disable.png"
import last_page from '../../../components/final_page.png'
import last_page_off from '../../../components/final_page_disable.png'
import order_desc from '../../../components/1.png'
import order_cresc from '../../../components/2.png'


import Connect_cidades_admin from '../../../connections/logged-admin/connect-cidade'
import Modal_cidades from './modal-cidades'

const Cidades_admin = (props)=>{
    const service_cidade = new Connect_cidades_admin()
    const [filtroesolution,setFiltroesolution] = useState('')
    const [filtrowpa,setFiltrowpa] = useState('')
    const [filtrowam,setFiltrowam] = useState('')
    const [offset,setOffset] = useState(0)
    const [limit,setLimit] = useState(14)
    const [flag_order,setFlag_order] =useState(1)

    const [order,setOrder]=useState(order_cresc)
    
    const [next,setNext] = useState(next_page_off)
    const [last,setLast] = useState(last_page_off)
    const [first,setFirst] = useState(first_page_off)
    const [back,setBack] = useState(back_page_off)
    const [disablenext,setDisablenext]=useState(true)
    const [disableback,setDisableback]=useState(true)
    const [disablefirst,setDisablefirst]=useState(true)
    const [disablelast,setDisablelast]=useState(true)

    const [currentpage,setCurrentpage]=useState(1)
    const [maxpage,setMaxpage]=useState(1)

    const [obj,setObj]=useState([])

    const [modaladd,setModaladd]=useState(false)

    const [idesolution,setIdesolution] = useState('')
    const [descesolution,setDescestoluion] = useState('')
    const [ufesolution,setUfestolution] = useState('')

    const [idwpa,setIdwpa] = useState('')
    const [descwpa,setDescwpa] = useState('')
    const [ufwpa,setUfwpa] = useState('')

    const [idwam,setIdwam] = useState('')
    const [descwam,setDescwam] = useState('') 
    const [ufwam,setUfwam] = useState('')

    const [id_depara_wpa,setId_depara_wpa]=useState('')
    const [id_depara_wam,setId_depara_wam]=useState('')

    const component_modal = ()=>{
        if(modaladd===true){
            return(
                <Modal_cidades isOpen={modaladd} onRequestClose={e=>{setModaladd(!modaladd)}}
                idesolution={idesolution} descesolution={descesolution} ufesolution={ufesolution}
                idwpa={idwpa} descwpa={descwpa} ufwpa={ufwpa} idwam={idwam} descwam={descwam}
                ufwam={ufwam} id_depara_wpa={id_depara_wpa} id_depara_wam={id_depara_wam}/>
            )
        }
    }

    useEffect(async()=>{
        if(modaladd===false){
            await buscar()
            limpar_var_modal()
        }
    },[modaladd])

    const fechar_abrir_modal = (Obj) =>{
        if(Obj!=''){
            setIdesolution(Obj.id_esolution)
            setDescestoluion(Obj.desc_esolution)
            setUfestolution(Obj.uf_esolution)

            setIdwpa(Obj.id_wpa)
            setDescwpa(Obj.desc_wpa)
            setUfwpa(Obj.uf_wpa)
            setId_depara_wpa(Obj.id_depara_wpa)

            setIdwam(Obj.id_wam)
            setDescwam(Obj.desc_wam)
            setUfwam(Obj.uf_wam)
            setId_depara_wam(Obj.id_depara_wam)

            setModaladd(!modaladd)
        }
        else if(Obj ==='') {
            setModaladd(!modaladd)
        }
        
    }

    useEffect(async()=>{
        const service = new Geral()
        await service.get_user()
        .then(res=>{
            if(res.data.profile===2){
                props.history.push("/home")
            }
        })
        .catch(erro=>{
            if(erro.response.status===401){
              Cookie.remove('connect.sid')
              Cookie.remove('profile.sid')
              window.location.href="/"
            }
        })
    },[Cidades_admin])

    useEffect(async()=>{
        await buscar()
    },[])

    useEffect(()=>{
        setMaxpage(1)
        setCurrentpage(1)
        setLimit(14)
        setOffset(0)
        setObj([])
    },[filtroesolution,filtrowpa,filtrowam])

    useEffect(async()=>{
        if(obj!=[]){
            if(currentpage!=1||maxpage!=1){
                setObj([])
                await buscar()
            }
            if(currentpage-1<1){
                setDisableback(true)
                setDisablefirst(true)
                setBack(back_page_off)
                setFirst(first_page_off)
                
            }
            else{
                setDisableback(false)
                setDisablefirst(false)
                setBack(back_page)
                setFirst(first_page)
            }
            if(currentpage===maxpage){
                setNext(next_page_off)
                setLast(last_page_off)
                setDisablenext(true)
                setDisablelast(true)
            }
            else{
                setNext(next_page)
                setLast(last_page)
                setDisablenext(false)
                setDisablelast(false)
            }
        }
    },[currentpage,maxpage,flag_order])

    const limpar_var_modal = ()=>{
        setIdesolution('')
        setDescestoluion('')
        setUfestolution('')
        setIdwpa('')
        setDescwpa('')
        setUfwpa('')
        setIdwam('')
        setDescwam('')
        setUfwam('')
        setId_depara_wpa('')
        setId_depara_wam('')
    }

    const limpar = ()=>{
        setMaxpage(1)
        setCurrentpage(1)
        setLimit(14)
        setOffset(0)
        setObj([])
        setFiltroesolution('')
        setFiltrowam('')
        setFiltrowpa('')
        setNext(next_page_off)
        setLast(last_page_off)
        setDisablenext(true)
        setDisablelast(true)
        setDisableback(true)
        setDisablefirst(true)
        setBack(back_page_off)
        setFirst(first_page_off)
    }

    const buscar = async() =>{
        let url = `filtroesolution=${filtroesolution}&filtrowpa=${filtrowpa}&filtrowam=${filtrowam}&`
        url=url + `offset=${offset}&limit=${limit}&flag_order=${flag_order}`
        await service_cidade.getCidades(url)
        .then(res=>{
            setObj(res.data.data)
            var arrendondar = Math.ceil(res.data.count/14);
            if(arrendondar===0){
                arrendondar=1
            }
            setMaxpage(arrendondar)
        })
        .catch(erro=>{
            if(erro.response.status===401){
              Cookie.remove('connect.sid')
              Cookie.remove('profile.sid')
              window.location.href="/"
            }
        })
    }

    const keyEnter = async(event)=>{
        if(event.charCode === 13){
            await buscar()
        }
    }

    const keyentermodal = (event,Obj)=>{
        if(event.charCode === 13){
            fechar_abrir_modal(Obj)
        }
    }

    const rows = obj.map( Obj=>{

        return (
            <tr key={Obj.id_depara_wpa+Obj.id_depara_wam}>
                <td><input className="inputfiltro" defaultValue={Obj.id_desc_esolution} 
                    onKeyPress={e=>keyentermodal(e,Obj)} readOnly/>
                </td>
                <td><input className="inputfiltro" defaultValue={Obj.id_desc_wpa}
                    onKeyPress={e=>keyentermodal(e,Obj)} readOnly/>
                </td>
                <td><input className="inputfiltro" defaultValue={Obj.id_desc_wam} 
                    onKeyPress={e=>keyentermodal(e,Obj)} readOnly/>
                </td>
            </tr>
        )
    })


    return(
        <div className="cidadeview">
            <div id="container">
                <div className="row" style={{backgroundColor:'#EBECEF',marginTop:'1%'}}>
                    <div className="col">
                        <label style={{fontWeight:'bold',fontSize:'18px'}}>Configurações {">"} Cidades</label>
                    </div>
                </div>
                <div className="row" id="rowtable">
                    <div className="col">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width:'33%'}}>
                                        <button className="btnorder" 
                                        onClick={()=>{
                                            if(flag_order===1){
                                                setOrder(order_desc)
                                                setFlag_order(2)
                                            }
                                            else{
                                                setOrder(order_cresc)
                                                setFlag_order(1)
                                            }
                                        }}>
                                            <img src={order} width="25px"/>
                                        </button>
                                        <input className="inputfiltro" type="text" 
                                        placeholder="eSolution"
                                        value={filtroesolution} 
                                        onChange={(e)=>setFiltroesolution(e.target.value)}
                                        onKeyPress={keyEnter}
                                        />
                                    </th >
                                    <th style={{width:'33%'}} className="thborder">
                                        <input className="inputfiltro" type="text" 
                                        placeholder="WPA"
                                        value={filtrowpa} 
                                        onChange={(e)=>setFiltrowpa(e.target.value)}
                                        onKeyPress={keyEnter}/>
                                    </th>
                                    <th style={{width:'33%'}}>
                                        <input className="inputfiltro" type="text"
                                        placeholder="WAM"
                                        value={filtrowam} 
                                        onChange={(e)=>setFiltrowam(e.target.value)}
                                        onKeyPress={keyEnter}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row" id="rowfooter">
                    <div className="col" style={{textAlign:'left',marginTop:"0.7%"}}>
                        <button className="open-modal-add" style={{marginLeft:'0.8%'}}
                        onClick={e=>fechar_abrir_modal('')}>
                            <label className="labeladd">ADICIONAR</label>
                            <img src={adicionar} width="16px" className="img-add"/>
                        </button>
                    </div>
                    <div className="col" id="pagenation" style={{marginTop:"0.7%"}}>
                        <button className="btnpages"
                        disabled={disablefirst}
                        onClick={()=>{
                            setCurrentpage(1)
                            setOffset(0)
                        }}>
                            <img src={first} width="15px" style={{maxHeight:"20px",minHeight:'20px'}}/>
                        </button>

                        <button className="btnpages"
                        disabled={disableback}
                        onClick={()=>{
                            setCurrentpage(currentpage-1)
                            setOffset(offset-14)
                        }}>
                            <img src={back} width="12px"/>
                        </button>

                        {currentpage}/{maxpage}
                        
                        <button className="btnpages"
                        disabled={disablenext}
                        onClick={()=>{
                            setCurrentpage(currentpage+1)
                            setOffset(offset+14)
                        }}>
                            <img src={next} width="12px"/>
                        </button>

                        <button className="btnpages"
                        disabled={disablelast}
                        onClick={()=>{
                            setCurrentpage(maxpage)
                            setOffset(maxpage*14-14)
                        }}>
                            <img src={last} width="15px" style={{maxHeight:"20px",minHeight:'20px'}}/>
                        </button>
                    </div>
                    <div className="col">

                    </div>
                </div>
            </div>
            {component_modal()}
        </div>
    )
}

export default Cidades_admin