import React,{useState} from 'react'
import logo from './newlogo.jpg'
import footer from './footer.png'
import { mostrarMensagem } from "../../components/toasrt"
import 'typeface-montserrat'
import './login.css'
import Connect_login from '../../connections/login/connect-login'
import Geral from '../../connections/main/geral'
import Cookie from 'js-cookie'


const Login =(props)=>{

    const service = new Connect_login()
    const service_geral = new Geral()
    const [username,setUsername]=useState('')
    const [password,setPassword]=useState('')
    const [loading,setLoading]=useState(false)

    const logar = async()=>{
        setLoading(true)
        if(username===''){
            mostrarMensagem('Erro','error','Digite o usuário')
            setLoading(false)
        }
        else if(password===''){
            mostrarMensagem('Erro','error','Digite a senha')
            setLoading(false)
        }
        else{
            await service.login({
                username:username,
                password:password
            }).then(async(response)=>{
                if(response.status===200){
                    await service_geral.getToken()
                    .then(res=>{
                        setLoading(false)
                        Cookie.set("profile.sid",res.data.token)
                        props.history.push('/home/admin-dashboard')
                    })
                }
            }).catch(erro=>{
                setLoading(false)
                console.log(erro)
                mostrarMensagem('Erro','error',erro.response.data.erro)
            })
        }
    }

    const keyEnter = (event)=>{
        if(event.charCode ===13){
            logar()
        }
    }

    return(
        <div className="login">
            <img src={logo} alt='' width="100%" style={{"marginTop":"4%"}}/>
            <div className="container">
                <div className="row">
                    <div className="form-group">
                        <input
                        type="text" className="form-control"
                        name="usuario" value={username} id="inputID"
                        onChange={e=>setUsername(e.target.value)} onKeyPress={keyEnter}
                        placeholder="Usuario"
                        />
                    </div>
                    <div className="form-group">
                        <input
                        type="password" className="form-control"
                        name="senha" value={password} id="inputID2"
                        onChange={e=>setPassword(e.target.value)} onKeyPress={keyEnter}
                        placeholder="Senha"
                        />
                    </div>
                    <div className="form-group">
                        <button type="button"
                            className="btn"
                            value={loading?"Loading...":"Login"}
                            disabled={loading} 
                            onClick={e=>{
                                logar()
                            }}
                            >ENTRAR
                        </button>
                    </div>
                </div>
            </div>
            <div className="footer">
                <img src={footer} alt='' width="100%"/>
            </div>
        </div>
        
    )   
}

export default Login