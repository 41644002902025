import ApiService from "../main/apiconnect";

class Connect_estado_civil_admin extends ApiService{
    constructor(){
        super('/api')
    }
    
    getStateCivil(url){
        let params = `/fdt_civilState?${url}`
        return this.get(params)
    }

    insert_update(params){
        return this.post("/fdt_civilState",params)
    }

    getECivil(url){
        let params = `/fdt_ecivil?${url}`
        return this.get(params)
    }

    insertECivil(params){
        return this.post("/fdt_ecivil",params)
    }

    updateECivil(params){
        return this.put("/fdt_ecivil",params)
    }

}

export default Connect_estado_civil_admin