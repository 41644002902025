import React, { useEffect } from 'react'
import Geral from '../../connections/main/geral'
import Cookie from "js-cookie"
import PrivateRouteAdmin from '../../main/private-routes-admin'
import Vendas from '../logged-admin/vendas/vendas'
import Cidades from '../logged-admin/cidades/cidade'
import Trocar_senha_Adm from '../logged-admin/trocar-senha/trocar_senha_adm'
import EcivilAdmin from '../logged-admin/estado-civil/ecivil'
import Navbar_Admin from '../logged-admin/navbar/navbar-admin'
import {Auth_PSA,Auth_User,Auth_Admin_Cliente} from '../../main/Auth'
import './home.css'
import Dashboard from '../logged-admin/dashboard/dashboard'
import Modal from 'react-modal'

Modal.setAppElement("#root")
const Home = (props)=>{

    useEffect(async()=>{
        const service = new Geral()
        await service.get_user()
        .catch(error=>{
            Cookie.remove('connect.sid')
            Cookie.remove('profile.sid')
            window.location.href="/"
        })
    },[Home])

    const Change_profile=(props)=>{
        if(Auth_PSA()){
            return(
                <div style={{
                    textAlign:'center',
                    position:'fixed',
                    minWidth:'100%',
                    minWidth:"100%",
                    minHeight:'100%',
                    height:"100%",
                    backgroundSize:'cover',
                    backgroundPosition:'center',
                    backgroundColor:'#EBECEF',
                    overflow:"auto"
                }} className="home">
                    <Navbar_Admin/>
                    <PrivateRouteAdmin path={"/home/admin-vendas"} component={Vendas}/>
                    <PrivateRouteAdmin path={"/home/admin-trocar-senha"} component={Trocar_senha_Adm}/>
                    <PrivateRouteAdmin path={"/home/admin-estado-civil"} component={EcivilAdmin}/>
                    <PrivateRouteAdmin path={"/home/admin-cidades"} component={Cidades}/>
                    <PrivateRouteAdmin path={"/home/admin-dashboard"} component={Dashboard}/>
                </div>
            )
        }
        else if(Auth_Admin_Cliente()){
            return(
                <div style={{
                    textAlign:'center',
                    position:'fixed',
                    minWidth:'100%',
                    minWidth:"100%",
                    minHeight:'100%',
                    height:"100%",
                    backgroundSize:'cover',
                    backgroundPosition:'center',
                    backgroundColor:'#EBECEF',
                    overflow:"auto"
                }} className="home">
                    <Navbar_Admin/>
                    <PrivateRouteAdmin path={"/home/admin-vendas"} component={Vendas}/>
                    <PrivateRouteAdmin path={"/home/admin-estado-civil"} component={EcivilAdmin}/>
                    <PrivateRouteAdmin path={"/home/admin-cidades"} component={Cidades}/>
                    <PrivateRouteAdmin path={"/home/admin-dashboard"} component={Dashboard}/>
                    <PrivateRouteAdmin path={"/home/admin-trocar-senha"} component={Trocar_senha_Adm}/>
                </div>
            )
        }
        else if(Auth_User()){
            return(
                <div>
                    ola usuário
                </div>
            )
        }
    }

    return(
        <Change_profile/>
    )
}

export default Home