import React,{useEffect, useState} from 'react'
import Modal from 'react-modal'
import Cookie from "js-cookie"
import './modal-cidades.css'
import { mostrarMensagem } from "../../../components/toasrt"

import icon_clear from '../../../components/clear_off.png'
import Connect_cidades_admin from '../../../connections/logged-admin/connect-cidade'

const Modal_cidades = (props) =>{

    const service = new Connect_cidades_admin()

    const [idesolution,setIdesolution] = useState(props.idesolution)
    const [descesolution,setDescestoluion] = useState(props.descesolution)
    const [ufesolution,setUfestolution] = useState(props.ufesolution)

    const [idwpa,setIdwpa] = useState(props.idwpa)
    const [descwpa,setDescwpa] = useState(props.descwpa)
    const [ufwpa,setUfwpa] = useState(props.ufwpa)

    const [idwam,setIdwam] = useState(props.idwam)
    const [descwam,setDescwam] = useState(props.descwam) 
    const [ufwam,setUfwam] = useState(props.ufwam)

    const [id_depara_wpa,setId_depara_wpa]=useState(props.id_depara_wpa)
    const [id_depara_wam,setId_depara_wam]=useState(props.id_depara_wam)

    const limpar = () =>{
        props.idesolution!==null?setIdesolution(props.idesolution):setIdesolution('')
        props.descesolution!==null?setDescestoluion(props.descesolution):setDescestoluion('')
        props.idwpa!==null?setIdwpa(props.idwpa):setIdwpa('')
        props.descwpa!==null?setDescwpa(props.descwpa):setDescwpa('')
        props.idwam!==null?setIdwam(props.idwam):setIdwam('')
        props.descwam!==null?setDescwam(props.descwam):setDescwam('')
        props.ufesolution!==null?setUfestolution(props.ufesolution):setUfestolution('')
        props.ufwpa!==null?setUfwpa(props.ufwpa):setUfwpa('')
        props.ufwam!==null?setUfwam(props.ufwam):setUfwam('')
    }

    const update = async(grupo_empresa,id,desc,uf,id_depara,empresa) =>{
        await service.updateCidades(
            {
                "idesolution": idesolution,
                "descesolution": descesolution,
                "ufesolution": ufesolution,
                "id": id,
                "desc": desc,
                "uf": uf,
                "grupo":grupo_empresa,
                "id_depara": id_depara
            }
        ).then(res=>{
            if(res.status===200){
                mostrarMensagem("Sucesso","success",`Sucesso ao alterar cidade ${empresa}`)
            }
        })
        .catch(erro=>{
            if(erro.response.status===401){
              Cookie.remove('connect.sid')
              Cookie.remove('profile.sid')
              window.location.href="/"
            }
            else{
                mostrarMensagem("Erro","error",erro.response.data.msg)
            }
        })
    }

    const insert = async(grupo_empresa,id,desc,uf,empresa) =>{
        await service.insertCidades(
            {
                "idesolution": idesolution,
                "descesolution": descesolution,
                "ufesolution": ufesolution,
                "id": id,
                "desc": desc,
                "uf": uf,
                "grupo":grupo_empresa
            }
        ).then(res=>{
            if(res.status===201){
                mostrarMensagem("Sucesso","success",`Sucesso ao inserir cidade ${empresa}`)
            }
        })
        .catch(erro=>{
            if(erro.response.status===401){
              Cookie.remove('connect.sid')
              Cookie.remove('profile.sid')
              window.location.href="/"
            }
            else{
                mostrarMensagem("Erro","error",erro.response.data.msg)
            }
        })
    }

    const atrelar = async ()=>{
        let flag = 0
        if(idesolution===''&&descesolution===''&&ufesolution===''){
            mostrarMensagem("Erro","error","Informe os campos do eSolution")
            return true
        }

        if((idwpa===''&&descwpa===''&&ufwpa==='')&&(idwam===''&&descwam===''&&ufwam==='')){
            mostrarMensagem("Erro","error","Informe os campos do WPA ou WAM")
            return true
        }

        if(id_depara_wpa!=''&&id_depara_wpa!=null&&idwpa!=''&&descwpa!=''&&ufwpa!=''){
            if(idesolution!==props.idesolution||descesolution!==props.descesolution
            ||ufesolution!==props.ufesolution||idwpa!==props.idwpa||descwpa!==props.descwpa
            ||ufwpa!==props.ufwpa){
                await update('238',idwpa,descwpa,ufwpa,id_depara_wpa,'WPA')
            }
            else {
                flag = flag+1
            }
        }

        else if((id_depara_wpa===''||id_depara_wpa===null)&&idwpa!=''
        &&idwpa!=null&&descwpa!=''&&descwpa!=null&&ufwpa!=''&&ufwpa!=null){
            if(idesolution!==props.idesolution||descesolution!==props.descesolution
            ||ufesolution!==props.ufesolution||idwpa!==props.idwpa||descwpa!==props.descwpa
            ||ufwpa!==props.ufwpa){
                await insert('238',idwpa,descwpa,ufwpa,'WPA')
            }
            else {
                flag = flag+1
            }
        }

        if(id_depara_wam!=''&&id_depara_wam!=null&&idwam!=''&&descwam!=''&&ufwam!=''){
            if(idesolution!==props.idesolution||descesolution!==props.descesolution
            ||ufesolution!==props.ufesolution||idwam!==props.idwam||descwam!==props.descwam
            ||ufwam!==props.ufwam){
                await update('141',idwam,descwam,ufwam,id_depara_wam,'WAM')
            }
            else{
                flag = flag+1
            }
        }
        if((id_depara_wam===''||id_depara_wam===null)&&idwam!=''
        &&idwam!=null&&descwam!=''&&descwam!=null&&ufwam!=''&&ufwam!=null){
            if(idesolution!==props.idesolution||descesolution!==props.descesolution
            ||ufesolution!==props.ufesolution||idwam!==props.idwam||descwam!==props.descwam
            ||ufwam!==props.ufwam){
                await insert('141',idwam,descwam,ufwam,'WAM')
            }
            else{
                flag = flag+1
            }
        }

        if(flag>=2){
            mostrarMensagem("Aviso","warning","Dados não foram alterados")
        }
        else{
            setTimeout(() => {
                window.location.href="/home/admin-cidades"
            }, 1000)
        }

    }

    return(
        <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        className="modal-cidades"
        id="modal-cidades"
        contentLabel="My dialog"
        overlayClassName="myoverlay"
        closeTimeoutMS={50}>
            <div className="container" id="modal_cidades">
                <div className="row" style={{textAlign:'center',marginTop:'5px'}}>
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <label className="labeltitle">eSolution</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="labelheader">ID</label>
                            </div>
                            <div className="col-md-3">
                                <label className="labelheader">UF</label>
                            </div>
                            <div className="col-md-6">
                                <label className="labelheader">Nome</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <input value={idesolution} type="number"
                                onChange={e=>setIdesolution(e.target.value)}
                                />
                            </div>
                            <div className="col-md-3">
                                <input value={ufesolution}
                                onChange={e=>setUfestolution(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <input value={descesolution}
                                onChange={e=>setDescestoluion(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"  style={{borderBottom:"3px solid #dddddd"}}/>
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <label className="labeltitle">WPA</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="labelheader">ID</label>
                            </div>
                            <div className="col-md-3">
                                <label className="labelheader">UF</label>
                            </div>
                            <div className="col-md-6">
                                <label className="labelheader">Nome</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <input value={idwpa} type="number"
                                onChange={e=>setIdwpa(e.target.value)}
                                />
                            </div>
                            <div className="col-md-3">
                                <input value={ufwpa}
                                onChange={e=>setUfwpa(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <input value={descwpa}
                                onChange={e=>setDescwpa(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"  style={{borderBottom:"3px solid #dddddd"}}/>
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <label className="labeltitle">WAM</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="labelheader">ID</label>
                            </div>
                            <div className="col-md-3">
                                <label className="labelheader">UF</label>
                            </div>
                            <div className="col-md-6">
                                <label className="labelheader">Nome</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <input value={idwam} type="number"
                                onChange={e=>setIdwam(e.target.value)}
                                />
                            </div>
                            <div className="col-md-3">
                                <input value={ufwam}
                                onChange={e=>setUfwam(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <input value={descwam}
                                onChange={e=>setDescwam(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'100px',textAlign:'center'}}>
                    <div className="col">
                        <button className="btnatrelar"
                        onClick={atrelar}>
                            Atrelar
                        </button>
                        <button className="btnlimpar"
                        onClick={limpar}>
                            <img src={icon_clear} width="30px" />
                        </button>
                    </div>
                </div>    
            </div>
        </Modal>
    )
}
export default Modal_cidades