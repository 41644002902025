import React from 'react'
import {Redirect,Route} from 'react-router-dom'
import { Auth_Admin } from './Auth'

const PrivateRouteAdmin = ({ component:Component,...rest})=>{
    return(
        <Route {...rest} render={ props =>(
            Auth_Admin()?(<Component {...props}/>):
            <Redirect to ={{pathname:"/home",state:{from:props.location}}}/>
        )}
        />
    )
}

export default PrivateRouteAdmin