import React,{useEffect,useState} from 'react'
import Geral from '../../../connections/main/geral'
import Cookie from "js-cookie"
import './trocarsenhaadm.css'
import { mostrarMensagem } from "../../../components/toasrt"

const Trocar_senha_Adm = (props)=>{
    useEffect(async()=>{
        const service = new Geral()
        await service.get_user()
        .then(res=>{
            if(res.data.profile===2){
                props.history.push("/home")
            }
        })
        .catch(erro=>{
            if(erro.response.status===401){
              Cookie.remove('connect.sid')
              Cookie.remove('profile.sid')
              window.location.href="/"
            }
          })
    },[Trocar_senha_Adm])

    const [username,setUsername] = useState('')
    const [lastpass,setLastpass] = useState('')
    const [newpass,setNewpass] = useState('')
    const [confpass,setConfpass] = useState('')
    const [loading,setLoading]= useState(false)
    const service = new Geral()

    const verify_consistence=()=>{
        if(username===''){
            mostrarMensagem('Erro','error','Digite o usuário')
            setLoading(false)
            return false
        }
        else if(lastpass===''){
            mostrarMensagem('Erro','error','Digite a senha antiga')
            setLoading(false)
            return false
        }
        else if(newpass===''){
            mostrarMensagem('Erro','error','Digite a nova senha')
            setLoading(false)
            return false
        }
        else if(confpass===''){
            mostrarMensagem('Erro','error','Digite a confirmação da nova senha')
            setLoading(false)
            return false
        }
        else {
            return true
        }
    }

    const compare_pass=()=>{
        if(lastpass==newpass){
            mostrarMensagem('Erro','error','A nova senha não pode ser igual senha atual')
            setLoading(false)
            return false
        }
        else if(newpass!=confpass){
            mostrarMensagem('Erro','error','A senha e sua confirmação são diferentes')
            setLoading(false)
            return false
        }
        else {
            return true
        }
    }

    const alter_pass = async(props) =>{
        setLoading(true)
        if(verify_consistence()&&compare_pass()){
            await service.alter_password({
                username:username,
                lastpass:lastpass,
                newpass:newpass
            }).then(response =>{
                if(response.status===200){
                    mostrarMensagem('Sucesso','success','Senha alterada')
                    setLoading(false)
                    window.location.href="/home/admin-vendas"
                }
            }).catch(erro=>{
                if(erro.response.status===401){
                    setLoading(false)
                    Cookie.remove('connect.sid')
                    Cookie.remove('profile.sid')
                    window.location.href="/"
                }
                else{
                    setLoading(false)
                    mostrarMensagem('Erro','error',erro.response.data.msg)
                }
            })
            
        }
    }

    return(
        <div className="container_alter_pass">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <label>Alteração de senha</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <input className="usuario" type="text"
                                placeholder="Usuário" value={username}
                                onChange={e=>setUsername(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input className="senhaant" type="password" 
                                placeholder="Senha antiga" value={lastpass}
                                onChange={e=>setLastpass(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input className="novasenha" type="password" 
                                placeholder="Nova senha" value={newpass}
                                onChange={e=>setNewpass(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input className="confsenha" type="password" 
                                placeholder="Nova senha (Confirmar)" value={confpass}
                                onChange={e=>setConfpass(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <button className="btnsalvar"
                                onClick={alter_pass}
                                >Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trocar_senha_Adm