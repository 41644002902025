import React, { useEffect, useState } from 'react'
import './navbar-admin.css'
import logo_off from '../../../components/logo_off.png'
import logo_on from '../../../components/logo_on.png'
import icon from './icon.png'
import Cookie from "js-cookie"
import Geral from '../../../connections/main/geral'
import { NavLink } from 'react-router-dom'
import { Auth_Admin_Cliente } from '../../../main/Auth'

const Navbar_Admin = (props)=>{
    const service = new Geral()
    const [nmusuario,setNmusuario] =useState('')
    const [logo,setLogo] = useState(logo_off)

    useEffect(async()=>{
        await service.get_user()
        .then(res=>setNmusuario(res.data.nmusuario))
    })

    useEffect(()=>{
        setLogo(logo_off)
    },[Navbar_Admin])

    const deslogar = async()=>{
        
        await service.destroy()
        .then(res=>{
            Cookie.remove('connect.sid')
            Cookie.remove('profile.sid')
            window.location.href="/"
        }).catch(error=>{
            Cookie.remove('connect.sid')
            Cookie.remove('profile.sid')
            window.location.href="/"
        })
    }

    const style={
        color:'orange',
        fontWeight:'bold'
    }

    const teste =()=>{
        if(Auth_Admin_Cliente()){
            return(
                <NavLink activeStyle={style} className="trocar_senha" to="/home/admin-trocar-senha">
                    Trocar Senha
                </NavLink>
            )
        }
        else{
            return(
                <NavLink activeStyle={style} className="trocar_senha" to="/home/admin-trocar-senha">
                    Trocar Senha
                </NavLink>
            )
        }
    }


    // Resolução menor que 484 de largura, deve alterar para outro navbar
    return(
        <div className="navbar-admin">
            <div className="row">
                <div className="col" id="icon">
                    <div className="row" style={{width:'70px'}}>
                        <NavLink isActive={(match,localtion)=>{
                            if(!match){
                                setLogo(logo_off)
                            }
                            else{
                                setLogo(logo_on)
                            }
                        }} className="image" to="/home/admin-dashboard"><img src={logo} width="50px"/>
                        </NavLink>
                    </div>
                </div>
                <div className="col" id="colvendas">
                    <div className="row" id="rowvendas">
                        <div className="col">
                            <NavLink activeStyle={style} className="processos" to="/home/admin-vendas">Vendas
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="col" id="coldrop1">
                    <div className="row" id="rowdropdown3">
                        <div id='cssmenu'>
                            <ul style={{marginTop:'auto',marginBottom:'auto'}}>
                                <li>
                                    <label className='has-sub'>Configurações</label>
                                    <ul>
                                        <li className="lidropdown">
                                            <NavLink activeStyle={style} className="ecivil" to="/home/admin-estado-civil">Estado Civil</NavLink>
                                        </li>
                                        <li className="lidropdown">
                                            <NavLink activeStyle={style} className="teste" to="/home/admin-cidades">Municipio</NavLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col" id="user">
                    <div className="row" id="rowdropdown2" style={{float:"right"}}>
                        <div className="dropdown2" style={{textAlign:"center"}}>
                        <label className="labelnome" 
                            style={{fontSize:"12px",cursor:'pointer'}}>
                            {nmusuario}
                        </label>
                        <img src={icon} width="23px" style={{cursor:'pointer'}}/>
                            <div className="dropdown-content2">
                                {teste()}
                                <a className="sair" onClick={deslogar}>Sair</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar_Admin