import axios from 'axios'
import Cookie from "js-cookie"

let httpCliente = axios.create({
    baseURL: 'https://backend-sieconnector.psasistemas.com.br',
    withCredentials:true
})

class ApiService {
    constructor(apiurl){
        this.apiurl=apiurl
    }

    post(url,obj){
        const requestURL = `${this.apiurl}${url}`
        return httpCliente.post(requestURL,obj,{
            headers: {'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + Cookie.get("profile.sid")}
        })
    }

    put(url,obj){
        const requestURL = `${this.apiurl}${url}`
        return httpCliente.put(requestURL,obj,{
            headers: {'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + Cookie.get("profile.sid")}
        })
    }

    delete(url){
        const requestURL = `${this.apiurl}${url}`
        return httpCliente.delete(requestURL,{
            headers: {'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + Cookie.get("profile.sid")}
        })
    }

    get(url){
        const requestURL = `${this.apiurl}${url}`
        return httpCliente.get(requestURL,{
            headers: {'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + Cookie.get("profile.sid")}
        })
    }
}

export default ApiService
